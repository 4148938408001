import { useContext, useEffect, useState } from "react";
import DesktopLayout from "../../../layout/DesktopLayout";
import MobileLayout from "../../../layout/MobileLayout";
import breakpoints from "../../../theme/antdesign";
import HeaderDocument from "./HeaderDocument";
import useScreenSize from "../../../hooks/useScreensize";
import Context from "../../../context/Context";
import { useParams } from "react-router-dom";
import { ThianaAssistantMode, emptyReport } from "../../../types/Reports";
import { ReportOutput } from "@thiana/api-thiana-client";
import { Flex, Tour, TourStepProps, Typography, message } from "antd";
import { Template } from "../../../types/Templates";
import useProfessionals from "../../../hooks/useProfessionals";
import { apiReports } from "../../../api-configuration/Configuration";
interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  mode: ThianaAssistantMode;
  steps: TourStepProps[];
}

export default function Document(props: Props) {
  const {
    isMenuHidden,
    dispatchFlow,
    updateIsMenuHidden,
    updateIsMicrophoneAvailable,
    openTour,
    professional,
    currentReport,
    updateCurrentReport,
    updateOpenTour,
  } = useContext(Context);
  const { id } = useParams();
  const { currentScreenSize } = useScreenSize();
  const [messageApi, contextHolder] = message.useMessage();
  const [isVisible, setIsVisible] = useState(false);
  const { patchProfessional } = useProfessionals();
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // On récupère le report quand l'id change dans l'URL
  const fetchReportByID = async (id: string) => {
    let report: ReportOutput;
    const response = await apiReports.reportGetRaw({
      id,
    });
    if (response.raw.status === 200) {
      report = await response.raw.json();
      updateCurrentReport(report.data[0]);
    }
  };

  useEffect(() => {
    // Vérifier si la permission d'accéder au microphone a été accordée
    (async () => {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(
          (stream) => {
            updateIsMicrophoneAvailable(true);
          },
          (e) => {
            updateIsMicrophoneAvailable(true);
            messageApi.open({
              type: "warning",
              content:
                "Vous n'avez pas autorisé l'accès au microphone. Veuillez le faire dans les paramètres de votre navigateur.",
            });
          }
        );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatchFlow({ type: "SWITCH_REPORT" });
      fetchReportByID(id);
    } else {
      if (currentReport)
        updateCurrentReport({
          ...emptyReport,
          report_type: currentReport?.report_type,
        });
      else updateCurrentReport(emptyReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async () => {
      if (
        professional &&
        professional.is_assistant_tutorial_displayed === false
      ) {
        updateOpenTour(true);
        // On met à jour le professionel
        await patchProfessional(professional.id, {
          is_assistant_tutorial_displayed: true,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professional]);

  return (
    <div
      className={isVisible ? "slide-in active" : "slide-in"}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: `calc(100vh - 20px)`,
        zIndex: 999,
        gap: 10,
      }}
      onClick={() =>
        currentScreenSize < breakpoints.sm && !isMenuHidden
          ? updateIsMenuHidden(true)
          : null
      }
    >
      <Tour
        open={openTour}
        onClose={() => updateOpenTour(false)}
        onFinish={() => console.log("Tour finished")}
        steps={props.steps}
        disabledInteraction={true}
      />
      {contextHolder}
      <HeaderDocument SOCKET_ASR={props.SOCKET_ASR} />

      <Flex vertical style={{ flex: 1 }}>
        <div style={{ display: "flex", flex: 1 }}>
          {currentScreenSize < breakpoints.sm ? (
            <MobileLayout
              SOCKET_ASR={props.SOCKET_ASR}
              templates={props.templates}
              setTemplates={props.setTemplates}
              mode={props.mode}
            />
          ) : (
            <DesktopLayout
              SOCKET_ASR={props.SOCKET_ASR}
              templates={props.templates}
              setTemplates={props.setTemplates}
              mode={props.mode}
            />
          )}
        </div>
      </Flex>

      <Typography
        style={{
          textAlign: "center",
          fontSize: 10,
          color: "grey",
        }}
      >
        Thiana utilise un modèle d'IA génératif. Par conséquent, les
        observations médicales générées peuvent comporter des erreurs ou être
        incomplètes. Veillez à toujours vérifier le contenu de l'observation
        avant de vous en servir.
      </Typography>
    </div>
  );
}
