import {
  AudioMutedOutlined,
  AudioOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Flex, Tooltip } from "antd";
import { useContext } from "react";
import Context from "../../../context/Context";
import useASR from "../../../hooks/useASR";
import useASRCypress from "../../../hooks/useASRCypress";
import useLLM from "../../../hooks/useLLM";
import useReports from "../../../hooks/useReports";
import { EMPTY_UUID } from "../../../utils/constants";
import { Template } from "../../../types/Templates";
import { isIAAllowed } from "../../../utils/security";
import { ThianaAssistantMode } from "../../../types/Reports";

const tip =
  "En mode compte-rendu, la sélection d'un modèle est obligatoire pour pouvoir lancer la génération.";

const StarsFilled = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-stars"
    viewBox="0 0 16 16"
  >
    <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
  </svg>
);
interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;

  mode: ThianaAssistantMode;
  templates: Template[];
}

export default function ControlCommands(props: Props) {
  const { currentReport, mode } = useContext(Context);
  // Si on est en mode report et qu'aucun template n'est sélectionné, on bloque la génération
  // Si on est en mode conversation, on regarde si il y a une transcrription
  const checkDisabled = () => {
    if (props.mode === "conversation" && currentReport?.transcription !== "")
      return false;
    else if (
      props.mode === "statement" &&
      currentReport?.report_template_id !== EMPTY_UUID &&
      currentReport?.report_template_id !== ""
    )
      return false;
    else return true;
  };
  const {
    flowState,
    dispatchFlow,
    updateCurrentTab,
    isASRProcessing,
    isMicrophoneAvailable,
    subscription,
    stepsRef,
  } = useContext(Context);
  const { startTranscription, stopRecording } = useASR({
    SOCKET_ASR: props.SOCKET_ASR,
  });
  const { startTranscriptionFromCypress, stopRecordingFromCypress } =
    useASRCypress({
      SOCKET_ASR: props.SOCKET_ASR,
    });
  const { startGeneration, stopGeneration } = useLLM();

  const { resetAppState } = useReports({});

  const renderCommands = () => {
    switch (flowState.currentState) {
      case "READY":
        return (
          <Tooltip
            title={
              !isIAAllowed(subscription?.status)
                ? "Un abonnement est nécessaire pour utiliser la reconnaissance vocale."
                : !isMicrophoneAvailable
                ? "La permission d'utiliser le micro est nécessaire."
                : null
            }
          >
            <Button
              block
              icon={<AudioOutlined />}
              type="primary"
              size="large"
              disabled={
                !isMicrophoneAvailable || !isIAAllowed(subscription?.status)
              }
              onClick={() => {
                startTranscription();
                updateCurrentTab("transcription");
                dispatchFlow({ type: "COMMAND_LAUNCH_ASR" });
              }}
            >
              Transcrire
            </Button>
          </Tooltip>
        );
      case "RECORDING":
        return (
          <>
            <button
              data-cy="action-transcription-pause"
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: 10,
                height: 10,
                padding: 0,
                margin: 0,
                position: "fixed",
                top: 0,
                left: 10,
              }}
              onClick={() => {
                stopRecordingFromCypress();
                updateCurrentTab("transcription");
                dispatchFlow({ type: "COMMAND_PAUSE_ASR" });
              }}
            ></button>
            <Button
              block
              icon={<AudioMutedOutlined />}
              type="default"
              size="large"
              danger
              onClick={() => {
                stopRecording();
                updateCurrentTab("transcription");
                dispatchFlow({ type: "COMMAND_PAUSE_ASR" });
              }}
            >
              Pause
            </Button>
            <Tooltip
              title={
                !isIAAllowed(subscription?.status)
                  ? "Un abonnement est nécessaire pour utiliser la génération de document."
                  : checkDisabled()
                  ? mode === "statement"
                    ? tip
                    : "Une transcription est nécessaire pour pouvoir utiliser la génération."
                  : null
              }
            >
              <Button
                block
                icon={StarsFilled}
                type="primary"
                size="large"
                disabled={checkDisabled() || !isIAAllowed(subscription?.status)}
                onClick={() => {
                  stopRecording(true);
                  updateCurrentTab("generation");
                  dispatchFlow({ type: "COMMAND_LAUNCH_LLM" });
                }}
              >
                {props.mode === "statement"
                  ? "Générer le compte-rendu"
                  : "Générer l'observation"}
              </Button>
            </Tooltip>
          </>
        );
      case "WAITING_FOR_LAST_ASR_FEEDBACK":
        return (
          <>
            <Button
              block
              icon={<LoadingOutlined />}
              type="default"
              size="large"
              disabled
            >
              Chargement...
            </Button>
            <Tooltip
              title={
                !isIAAllowed(subscription?.status)
                  ? "Un abonnement est nécessaire pour utiliser la génération de document."
                  : checkDisabled()
                  ? mode === "statement"
                    ? tip
                    : "Une transcription est nécessaire pour pouvoir utiliser la génération."
                  : null
              }
            >
              <Button
                block
                icon={StarsFilled}
                type="primary"
                size="large"
                disabled={checkDisabled() || !isIAAllowed(subscription?.status)}
              >
                {props.mode === "statement"
                  ? "Générer le compte-rendu"
                  : "Générer l'observation"}
              </Button>
            </Tooltip>
          </>
        );
      case "PAUSE":
        return (
          <>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                width: 10,
                height: 10,
                padding: 0,
                margin: 0,
                position: "fixed",
                top: 0,
                left: 0,
              }}
              data-cy="action-transcription-start"
              onClick={() => {
                startTranscriptionFromCypress();
                updateCurrentTab("transcription");
                dispatchFlow({ type: "COMMAND_LAUNCH_ASR" });
              }}
            ></button>
            <Flex gap={8} style={{ flex: 1 }}>
              <Tooltip
                title={
                  !isIAAllowed(subscription?.status)
                    ? "Un abonnement est nécessaire pour utiliser la reconnaissance vocale."
                    : !isMicrophoneAvailable
                    ? "La permission d'utiliser le micro est nécessaire."
                    : null
                }
              >
                <Button
                  ref={stepsRef[4]}
                  style={{ flex: 1, minWidth: 200 }}
                  block
                  icon={<AudioOutlined />}
                  type="default"
                  size="large"
                  disabled={
                    !isMicrophoneAvailable || !isIAAllowed(subscription?.status)
                  }
                  onClick={() => {
                    startTranscription();
                    updateCurrentTab("transcription");
                    dispatchFlow({ type: "COMMAND_LAUNCH_ASR" });
                  }}
                >
                  Transcrire
                </Button>
              </Tooltip>

              <Tooltip
                title={
                  !isIAAllowed(subscription?.status)
                    ? "Un abonnement est nécessaire pour utiliser la génération de document."
                    : checkDisabled()
                    ? mode === "statement"
                      ? tip
                      : "Une transcription est nécessaire pour pouvoir utiliser la génération."
                    : null
                }
              >
                <Button
                  ref={stepsRef[7]}
                  style={{ flex: 1, minWidth: 200 }}
                  block
                  icon={StarsFilled}
                  type="primary"
                  size="large"
                  disabled={
                    checkDisabled() || !isIAAllowed(subscription?.status)
                  }
                  onClick={() => {
                    startGeneration(currentReport?.transcription);
                    updateCurrentTab("generation");
                    dispatchFlow({ type: "COMMAND_LAUNCH_LLM" });
                  }}
                >
                  {props.mode === "statement"
                    ? "Générer le compte-rendu"
                    : "Générer l'observation"}
                </Button>
              </Tooltip>
            </Flex>
          </>
        );
      case "GENERATING":
        return (
          <>
            <Button
              block
              icon={<CloseOutlined />}
              type="primary"
              size="large"
              danger
              onClick={() => {
                stopGeneration();
                updateCurrentTab("generation");
                dispatchFlow({ type: "COMMAND_ABORT_LLM" });
              }}
            >
              Arrêter la génération
            </Button>
            <Button
              block
              icon={<LoadingOutlined />}
              type="primary"
              size="large"
              disabled
            >
              Génération en cours
            </Button>
          </>
        );
      case "WAITING_FOR_LAST_LLM_FEEDBACK":
        return (
          <>
            <Button
              block
              icon={<LoadingOutlined />}
              type="primary"
              size="large"
              danger
              disabled
              onClick={() => {
                stopGeneration();
                updateCurrentTab("generation");
                dispatchFlow({ type: "COMMAND_ABORT_LLM" });
              }}
            >
              Arrêter la génération
            </Button>
          </>
        );
      case "FINISHED":
        return (
          <>
            <Button
              block
              icon={<AudioOutlined />}
              type="default"
              size="large"
              onClick={() => {
                startTranscription();
                updateCurrentTab("transcription");
                dispatchFlow({ type: "COMMAND_LAUNCH_ASR" });
              }}
            >
              Reprendre
            </Button>
            <Tooltip
              title={
                checkDisabled()
                  ? mode === "statement"
                    ? tip
                    : "Une transcription est nécessaire pour pouvoir utiliser la génération."
                  : null
              }
            >
              <Button
                block
                icon={StarsFilled}
                type="primary"
                size="large"
                disabled={checkDisabled() || !isIAAllowed(subscription?.status)}
                onClick={() => {
                  startGeneration(currentReport?.transcription);
                  updateCurrentTab("generation");
                  dispatchFlow({ type: "COMMAND_LAUNCH_LLM" });
                }}
              >
                Générer à nouveau
              </Button>
            </Tooltip>
            <Button
              block
              icon={<PlusOutlined />}
              type="primary"
              size="large"
              onClick={() => {
                updateCurrentTab("transcription");
                if (isASRProcessing) stopRecording();
                resetAppState();
              }}
            >
              Nouveau document
            </Button>
          </>
        );
      default:
        return <p>Veuillez actualiser la page</p>;
    }
  };

  return renderCommands();
}
