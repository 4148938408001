import { ThemeConfig, theme } from "antd";
import { ThianaAssistantMode } from "../types/Reports";
import { ThianaAssistantTheme } from "../types/Theme";

export default function useLightTheme(mode: ThianaAssistantMode) {
  const { defaultAlgorithm } = theme;

  const lightTheme: ThemeConfig | undefined = {
    algorithm: defaultAlgorithm,
    token: {
      colorPrimary: mode === "conversation" ? "#6240dc" : "#2793f2",
    },
  };

  const specificLightThemeColors: ThianaAssistantTheme = {
    colorStatement: "#2793f2",
    colorConversation: "#6240dc",
    label: "light",
    sideBackgroundColor: "whitesmoke",
    contentBackgroundColor: "white",
    iconColor: "black",
  };

  return { lightTheme, specificLightThemeColors };
}
