import React, { useContext, useEffect, useState } from "react";
import { Button, Flex, Input, Modal, Tag, Tooltip, Typography } from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import Context from "../context/Context";
import { copyToClipboard } from "../functions/copyToClipboard";
import useReports from "../hooks/useReports";
import { useParams } from "react-router-dom";

export default function TranscriptionView() {
  let { id } = useParams();
  const [value, setValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const {
    mode,
    isASRProcessing,
    isLLMProcessing,
    flowState,
    isSaved,
    stepsRef,
    currentReport,
    updateCurrentReport,
    updateIsSaved,
  } = useContext(Context);
  const { autoSaveReport, autoCreateReport, updateReport } = useReports({});

  useEffect(() => {
    typeWriter(flowState.animatedLiveResponse);
    if (!isASRProcessing) setValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowState.fixedLiveResponse, isASRProcessing]);

  const typeWriter = (text: string, i = 0) => {
    if (i < text.length) {
      let newValue = flowState.fixedLiveResponse + text.slice(0, i + 1);
      newValue = newValue.replace(/([.!?])\s*/g, "$1\n");
      setValue(newValue);
      setTimeout(() => {
        typeWriter(text, i + 1);
      }, 8);
    }
  };
  const onChangeTranscription = (newValue: string) => {
    if (currentReport && mode) {
      updateIsSaved({ isSaved: false, updatedField: "transcription" });
      updateCurrentReport({
        ...currentReport,
        transcription: newValue,
      });
      if (id)
        autoSaveReport({
          ...currentReport,
          transcription: newValue,
        });
      else
        autoCreateReport({
          transcription: newValue,
          generation: "",
          title: "",
          report_type: mode,
        });
    }
  };

  const HeaderTranscription = () => (
    <Flex
      align="center"
      onMouseEnter={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
      style={{
        color: isFocus || isASRProcessing ? "#6240dc" : "#bbbbbb",
        marginBottom: 4,
      }}
    >
      <div style={{ flex: 1 }}>
        {currentReport?.transcription === "" ? (
          <Tag
            bordered={false}
            style={{
              fontSize: 10,
            }}
          >
            TRANSCRIPTION
          </Tag>
        ) : !isSaved.isSaved && isSaved.updatedField === "transcription" ? (
          <Tag bordered={false} color="orange" style={{ fontSize: 10 }}>
            MODIFICATION EN COURS <LoadingOutlined />
          </Tag>
        ) : (
          <Tag bordered={false} color="green" style={{ fontSize: 10 }}>
            TRANSCRIPTION <CheckOutlined style={{ fontSize: 8 }} />
          </Tag>
        )}
      </div>
      <div>
        <Tooltip title="Effacer la transcription" mouseEnterDelay={0.8}>
          <Button
            type={"text"}
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => setIsModalOpen(true)}
          />
        </Tooltip>

        <Tooltip title="Copier la transcription" mouseEnterDelay={0.8}>
          <Button
            type={"text"}
            size="small"
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(currentReport?.transcription)}
          />
        </Tooltip>
      </div>
    </Flex>
  );

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
      ref={stepsRef[5]}
    >
      <HeaderTranscription />
      {isASRProcessing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            border: "1px solid #6240dc",
            borderRadius: 6,
          }}
        >
          <div
            style={{
              marginTop: 4,
              marginLeft: 15,
              marginRight: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                lineHeight: 1.5714,
                fontSize: 14,
                padding: 0,
              }} //line-height similaire au Input.TextArea de ant design
            >
              <Typography>{currentReport?.transcription}</Typography>
              <Typography>
                {value.slice(
                  0,
                  flowState.liveResponseValidationIndices.validatedIndex
                )}
              </Typography>
              <Typography>
                {value.slice(
                  flowState.liveResponseValidationIndices.validatedIndex,
                  flowState.liveResponseValidationIndices.validatedIndex +
                    flowState.liveResponseValidationIndices.inValidationIndex
                )}
              </Typography>
              <Typography>
                <i>
                  {value.slice(
                    flowState.liveResponseValidationIndices.inValidationIndex +
                      flowState.liveResponseValidationIndices.validatedIndex
                  )}
                </i>
              </Typography>
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Input.TextArea
            onFocus={() => (isFocus ? setIsFocus(true) : null)}
            onBlur={() => setIsFocus(false)}
            style={{ flex: 1, textAlign: "justify" }}
            value={currentReport?.transcription}
            onChange={(e) => onChangeTranscription(e.target.value)}
            disabled={isLLMProcessing || isASRProcessing}
            onMouseEnter={() => setIsFocus(true)}
            onMouseLeave={() => setIsFocus(false)}
            data-cy="action-input-transcription"
          ></Input.TextArea>
        </div>
      )}
      <Modal
        title={
          <>
            <ExclamationCircleFilled
              style={{
                height: 32,
                width: 32,
                fontSize: 22,
                color: "#ff4d4f",
              }}
            />
            Etes-vous sûr de vouloir effacer la transcription ?
          </>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="back"
            type="default"
            onClick={() => setIsModalOpen(false)}
          >
            Annuler
          </Button>,
          <Button
            onClick={() => {
              if (currentReport) {
                updateCurrentReport({
                  ...currentReport,
                  transcription: "",
                });
                updateReport({
                  ...currentReport,
                  transcription: "",
                });
                setIsModalOpen(false);
              }
            }}
            type="primary"
            danger
            key="submit"
          >
            Supprimer
          </Button>,
        ]}
      >
        <p>
          Une fois supprimées, les données seront définitivement perdues.
          Etes-vous sur de vouloir continuer ?
        </p>
        <p>Si non, cliquez sur le bouton annuler</p>
      </Modal>
    </div>
  );
}
