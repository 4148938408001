import React, { useContext, useEffect, useState } from "react";
import { ContentState, Editor, EditorState } from "draft-js";
import { Template } from "../../../../types/Templates";
import Context from "../../../../context/Context";
import "draft-js/dist/Draft.css";

interface Props {
  currentTemplate: Template;
  setCurrentTemplate: React.Dispatch<React.SetStateAction<Template>>;
  setisSaved: React.Dispatch<React.SetStateAction<boolean>>;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
}

export default function EditorDraftJS(props: Props) {
  const { theme } = useContext(Context);
  const [previousContentState, setPreviousContentState] =
    useState<ContentState>();

  useEffect(() => {
    // Nouveau contenu que l'on souhaite utiliser pour le remplacement
    const newContentValue = props.currentTemplate?.content || "";

    // On crée un nouvel objet ContentState avec le nouveau contenu
    const newContentState = ContentState.createFromText(newContentValue);
    setPreviousContentState(newContentState);

    // On remplace entièrement le contenu en utilisant 'replace-with-text'
    const updatedEditorState = EditorState.push(
      props.editorState,
      newContentState,
      "insert-characters"
    );
    props.setEditorState(updatedEditorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentTemplate?.content]);

  const handleChange = (editorState: EditorState) => {
    // On met à jour l'éditeur
    props.setEditorState(editorState);

    // On réactive le bouton "Enregistrer"
    if (
      editorState.getCurrentContent().getPlainText() !==
      previousContentState?.getPlainText()
    ) {
      props.setisSaved(false);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        color: theme.label === "light" ? "black" : "whitesmoke",
        fontSize: 14,
      }}
      data-cy="action-new-model-content"
    >
      <Editor editorState={props.editorState} onChange={handleChange} />
    </div>
  );
}
