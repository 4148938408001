import { Report as ThianaReport } from "@thiana/api-thiana-client";
export type ClassifiedReports = { [key: string]: ThianaReport[] };
export const emptyReport: ThianaReport = {
  id: "",
  transcription: "",
  title: "",
  generation: "",
  professional_id: "",
  report_template_id: "",
  created_at: "",
  updated_at: "",
  feedbacks: [],
  report_type: "conversation",
};

export type ThianaAssistantMode = "statement" | "conversation";
