import { useContext, useState } from "react";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Flex, Modal, notification } from "antd";
import { deleteRecordById } from "../../../api/api";
import { emptyReport } from "../../../types/Reports";
import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { useNavigate } from "react-router-dom";
import Context from "../../../context/Context";

interface Props {
  isSelected: boolean;
  onClick: (arg: any) => void;
  report: ThianaReport;

  showSelectMany: boolean;
  selectedIDs: string[];
  setSelectedIDs: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function ItemReport(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let navigate = useNavigate();
  const { theme, reports, updateReports } = useContext(Context);

  const { updateCurrentReport, mode } = useContext(Context);

  const deleteReport = async (id: string) => {
    const response = await deleteRecordById(id);
    let foundedIndex = reports.findIndex(
      (report: ThianaReport) => report.id === id
    );
    let nextCurrentID: string;
    if (foundedIndex > 0) nextCurrentID = reports[foundedIndex - 1].id;
    else nextCurrentID = "";
    if (response.status === 204) {
      let tmpReports = [...reports];
      tmpReports.splice(foundedIndex, 1);
      // On retire l'élément supprimé de la liste
      updateReports(tmpReports);
      // On clean le currentReport si nextCurrentID est vide
      updateCurrentReport(emptyReport);
      // On redirige l'utilisateur vers un autre item de la liste (le précédent)
      navigate("/documents/" + nextCurrentID);
      // Notification pour prévenir de la suppression
      notification.success({
        message: "Suppression réussie",
        description: "Le document a bien été supprimé.",
        duration: 3,
      });
    }
    setIsModalOpen(false);
  };

  const isSwitchChecked = (): boolean => {
    // Si il y a un report en cours, on regarde le currentReport.report_type
    if (props.report) {
      if (
        props.report.report_type === "conversation" ||
        props.report.report_type === "none"
      )
        return true;
      return false;
    } else {
      if (mode === "conversation") return true;
      return false;
    }
  };

  const manageSelectedIDs = (checked: boolean, id: string) => {
    let tmp = [...props.selectedIDs];
    // Si on veut rajouter l'id dans la liste
    if (checked) {
      // On vérifie qu'il ne soit pas déjà dedans, si il n'y est pas on le met dedans
      if (tmp.findIndex((item: string) => item === id) === -1) {
        tmp.push(props.report.id);
        props.setSelectedIDs(tmp);
      }
    }
    // On veut enlever un id de la liste
    else {
      // Si il y est, on l'enlève
      if (tmp.findIndex((item: string) => item === id) !== -1) {
        let index = tmp.findIndex((item: string) => item === id);
        tmp.splice(index, 1);
        props.setSelectedIDs(tmp);
      }
    }
  };

  return (
    <Flex align={"center"} gap={"small"}>
      <Button
        size="small"
        style={{
          flex: 1,
        }}
        type="text"
        onClick={props.onClick}
      >
        <Flex gap={"small"} align="center">
          <div
            className={
              isSwitchChecked()
                ? "customSwitchChecked"
                : "customSwitchUnchecked"
            }
          >
            {isSwitchChecked() ? (
              <TeamOutlined
                style={{
                  fontSize: isSwitchChecked() ? 16 : 12,
                  transition: "font-size 0.5s ease",
                  color: theme.colorConversation,
                }}
              />
            ) : (
              <UserOutlined
                style={{
                  fontSize: !isSwitchChecked() ? 16 : 12,
                  transition: "font-size 0.5s ease",
                  color: theme.colorStatement,
                }}
              />
            )}
          </div>

          <div style={{ flex: 1, fontSize: 14, textAlign: "left" }}>
            {props.report.title
              ? props.report.title.length > 25
                ? props.report.title.slice(0, 25) + "..."
                : props.report.title
              : "Document sans titre"}
          </div>
        </Flex>
      </Button>

      {props.showSelectMany ? (
        <Checkbox
          checked={props.selectedIDs.includes(props.report.id)}
          onChange={(e) => manageSelectedIDs(e.target.checked, props.report.id)}
        />
      ) : props.isSelected ? (
        <Button
          size="small"
          style={{ width: 32 }}
          onClick={() => setIsModalOpen(true)}
          type="text"
          icon={<DeleteOutlined />}
        ></Button>
      ) : (
        <div style={{ width: 32 }}></div>
      )}

      <Modal
        title={
          <>
            <ExclamationCircleFilled
              style={{
                height: 32,
                width: 32,
                fontSize: 22,
                color: "#ff4d4f",
              }}
            />
            Êtes-vous sûr de vouloir supprimer ces données ?
          </>
        }
        open={isModalOpen}
        onOk={() => deleteReport(props.report.id)}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="back"
            type="default"
            onClick={() => setIsModalOpen(false)}
          >
            Annuler
          </Button>,
          <Button
            onClick={async () => {
              await deleteReport(props.report.id);
              setIsModalOpen(false);
            }}
            type="primary"
            danger
            key="submit"
          >
            Supprimer
          </Button>,
        ]}
      >
        <p>
          Une fois supprimées, les données seront définitivement perdues.
          Êtes-vous sur de vouloir continuer ?
        </p>
        <p>Si non, cliquez sur le bouton annuler.</p>
      </Modal>
    </Flex>
  );
}
