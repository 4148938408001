import GenerationView from "../views/GenerationView";
import TranscriptionView from "../views/TranscriptionView";
import ControlCommands from "../components/protected/commands/ControlCommands";
import { Template } from "../types/Templates";
import { ThianaAssistantMode } from "../types/Reports";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;

  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  mode: ThianaAssistantMode;
}
export default function DesktopLayout(props: Props) {
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1, gap: 8 }}>
      <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: 8 }}>
        <div style={{ display: "flex", flex: 1 }}>
          <TranscriptionView />
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <GenerationView
            templates={props.templates}
            setTemplates={props.setTemplates}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <ControlCommands
          SOCKET_ASR={props.SOCKET_ASR}
          mode={props.mode}
          templates={props.templates}
        />
      </div>
    </div>
  );
}
