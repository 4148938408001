import { Button, Flex, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  Template,
  TemplatesResponse,
  emptyTemplate,
} from "../../../../types/Templates";
import { PlusOutlined } from "@ant-design/icons";
import EditorDraftJS from "./EditorDraftJS";
import { getTemplateById } from "../../../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import useTemplates from "../../../../hooks/useTemplates";
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import ItemTemplate from "./ItemTemplate";
import { ThianaPagination } from "../../../../types/Pagination";
import Context from "../../../../context/Context";

interface Props {
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  templatesPagination: ThianaPagination;
  setTemplatesPagination: (
    value: React.SetStateAction<ThianaPagination>
  ) => void;
}

export default function MyTemplates(props: Props) {
  const [currentTemplate, setCurrentTemplate] =
    useState<Template>(emptyTemplate);
  const [isSaved, setisSaved] = useState(true);
  const [mode, setMode] = useState<"none" | "create" | "modify">("none");
  const { createNewTemplate, createNewTemplateVersion } = useTemplates({
    setTemplates: props.setTemplates,
  });
  let navigate = useNavigate();
  let { id } = useParams();
  // Déclaration de l'éditeur DraftJS
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(compositeDecorator)
  );
  const { theme } = useContext(Context);

  const createTemplate = async () => {
    let tmpRaw = convertContentStateToRaw();
    let status = await createNewTemplate({
      title: currentTemplate?.title || "",
      content: tmpRaw || "",
    });
    if (status === 201) setMode("modify");
  };

  useEffect(() => {
    if (props.templates[0]) {
      setMode("modify");
      navigate("/templates/" + props.templates[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.templates]);

  useEffect(() => {
    if (id) {
      setMode("modify");
      (async () => {
        let templatesResponse: TemplatesResponse;
        let response = await getTemplateById(id);
        if (response.status === 200) {
          templatesResponse = await response.json();
          if (templatesResponse.data)
            setCurrentTemplate(templatesResponse.data[0]);
        }
      })();
    } else {
      setCurrentTemplate(emptyTemplate);
    }
  }, [id]);

  const saveTemplate = async () => {
    let tmpRaw = convertContentStateToRaw();
    if (currentTemplate) {
      let tmpCurrentTemplate: Template = {
        ...currentTemplate,
        content: tmpRaw,
      };
      if (tmpCurrentTemplate) {
        setCurrentTemplate(tmpCurrentTemplate);

        let status = await createNewTemplateVersion(tmpCurrentTemplate);
        if (status === 201) {
          setisSaved(true);
        }
      }
    }
  };

  const handleChangeTitle = (value: string) => {
    setisSaved(false);
    if (currentTemplate)
      setCurrentTemplate({ ...currentTemplate, title: value });
  };

  const convertContentStateToRaw = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    return rawContentState.blocks.map((block) => block.text).join("\n");
  };

  return (
    <Flex gap={"small"} style={{ height: "90%", maxHeight: "90%" }}>
      <Flex
        vertical
        gap={"small"}
        style={{
          flex: 1,
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Button
          style={{ width: "100%" }}
          icon={<PlusOutlined />}
          onClick={() => {
            setMode("create");
            setCurrentTemplate(emptyTemplate);
            navigate("/templates");
          }}
          data-cy="action-new-model"
        >
          Nouveau modèle
        </Button>

        {/* TODO - Faire la recherche des modèles en bdd */}
        {/* <Search placeholder="Rechercher ..." style={{ marginBottom: 4 }} /> */}
        <div
          style={{ overflowY: "auto" }}
          // Fetch more templates
          onScrollCapture={(e: any) => {
            if (
              e.target.scrollHeight - e.target.clientHeight - 1 <
                e.target.scrollTop &&
              props.templatesPagination.currentPage <
                props.templatesPagination.nbPages
            ) {
              props.setTemplatesPagination({
                ...props.templatesPagination,
                currentPage: props.templatesPagination.currentPage + 1,
              });
            }
          }}
        >
          <Flex vertical justify="center" gap={"small"}>
            {props.templates.map((template: Template, index: number) => {
              return (
                <ItemTemplate
                  index={index}
                  templates={props.templates}
                  setTemplates={props.setTemplates}
                  key={index}
                  template={template}
                  isSelected={id === template.id}
                  setMode={setMode}
                />
              );
            })}
          </Flex>
        </div>
      </Flex>
      {mode !== "none" ? (
        <Flex vertical gap={"small"} style={{ flex: 3, marginBottom: 10 }}>
          <Input
            style={{ fontSize: 14 }}
            placeholder="Titre du modèle"
            value={currentTemplate?.title}
            onChange={(e) => handleChangeTitle(e.target.value)}
            data-cy="action-new-model-title"
          />
          <div
            style={{
              flex: 1,
              border:
                theme.label === "dark"
                  ? "1px solid #424242"
                  : "1px solid #d9d9d9",
              backgroundColor:
                theme.label === "dark" ? theme.sideBackgroundColor : "inherit",
              borderRadius: 6,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <EditorDraftJS
              currentTemplate={currentTemplate}
              setCurrentTemplate={setCurrentTemplate}
              setisSaved={setisSaved}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </div>
          <Button
            disabled={isSaved}
            onClick={mode === "create" ? createTemplate : saveTemplate}
            type="primary"
            data-cy="action-create-model"
          >
            {mode === "create" ? "Créer ce modèle" : "Enregistrer"}
          </Button>
        </Flex>
      ) : (
        <Flex vertical gap={"small"} style={{ flex: 3, marginBottom: 10 }}>
          <div></div>
        </Flex>
      )}
    </Flex>
  );
}

// Style à appliquer aux " x " et "x "
const XSpan = (props: any) => {
  return (
    <span {...props} style={{ background: "#175f7923", borderRadius: 6 }}>
      {props.children}
    </span>
  );
};
// Style à appliquer aux "[ ]"
const BracketsSpan = (props: any) => {
  return (
    <span {...props} style={{ background: "#17507920" }}>
      {props.children}
    </span>
  );
};

// Déclaration des décorateurs
const compositeDecorator = new CompositeDecorator([
  {
    strategy: xStrategy,
    component: XSpan,
  },
  {
    strategy: bracketsStrategy,
    component: BracketsSpan,
  },
]);

// Fonction pour checker si l'éditeur contient des " x " ou des lignes qui commençent par "x " (sans lettre devant)
function findWithXRegex(
  contentBlock: ContentBlock,
  callback: (arg0: any, arg1: any) => void
) {
  const text = contentBlock.getText();
  let matchArr, start;
  while (
    (matchArr = X_REGEX.exec(text)) !== null ||
    (matchArr = X_START_REGEX.exec(text)) !== null
  ) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

// Fonction pour checker si l'éditeur contient des "[...]"
function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (arg0: any, arg1: any) => void
) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

// Regex pour les décorateurs de l'editorState (DraftJS)
const X_REGEX = / x /g;
const X_START_REGEX = /^(x |x\b)/g;
const BRACKETS_REGEX = /\[([^[\]]*)\]/g;

// x strategy
function xStrategy(
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) {
  findWithXRegex(contentBlock, callback);
}
// brackets strategy
function bracketsStrategy(
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) {
  findWithRegex(BRACKETS_REGEX, contentBlock, callback);
}
