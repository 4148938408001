import { Divider, Flex, Tag, TourProps, Typography } from "antd";
import { useRef, useState } from "react";
import { Image } from "antd";
import welcomeSVG from "../assets/tour/1bienvenue.svg";
import newdocSVG from "../assets/tour/2newdoc.svg";
import transcriptionSVG from "../assets/tour/3transcription.svg";
import titleSVG from "../assets/tour/4titre.svg";
import choiceSVG from "../assets/tour/5choix.svg";
import generationSVG from "../assets/tour/6generation.svg";
import conversationPNG from "../assets/tour/conversation.png";
import soloPNG from "../assets/tour/solo.png";
import hdsSVG from "../assets/tour/hds.svg";
import {
  EyeOutlined,
  FileOutlined,
  FileTextFilled,
  InfoCircleFilled,
  SmileOutlined,
  StarOutlined,
} from "@ant-design/icons";

interface Props {
  updateOpenTour: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function useTour(props: Props) {
  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const [stepsRef, setStepsRef] = useState<React.MutableRefObject<null>[]>([
    ref0,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6,
    ref7,
    ref8,
    ref9,
    ref10,
  ]);

  const steps: TourProps["steps"] = [
    {
      title: "",
      description: (
        <Flex align={"center"} style={{ margin: 20 }}>
          <button
            data-cy="action-quit-tour"
            style={{
              position: "absolute",
              width: 10,
              height: 10,
              top: 0,
              left: 0,
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => props.updateOpenTour(false)}
          ></button>
          <div>
            <b>Bienvenue sur Thiana Assistant Intelligent !</b>
            <br></br> Prêt à gagner du temps en rédigeant vos observations et
            comptes rendus médicaux plus rapidement ?
          </div>

          <Image width={200} src={welcomeSVG} alt="bienvenue" preview={false} />
        </Flex>
      ),

      nextButtonProps: { children: <div>Oui, c'est parti !</div> },
      target: () => ref0.current,
    },
    {
      title: "",
      description: (
        <Flex gap={"large"} align={"center"} style={{ margin: 20 }}>
          <Image
            width={40}
            src={newdocSVG}
            alt="new-document"
            preview={false}
          />
          <div>
            <b>Nouveau document</b>
            <br></br>
            Pour créer un nouveau document, cliquer ici !
          </div>
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref1.current,
    },
    {
      title: <Image width={40} src={choiceSVG} alt="choice" preview={false} />,
      description: (
        <Flex style={{ margin: 20 }} gap={"small"}>
          <Flex vertical gap={"large"} align="center" style={{ flex: 1 }}>
            <Image width={150} src={soloPNG} alt="choice" preview={false} />
            <div>
              <Typography>
                <b>Vous êtes seul ? </b>
                <br />
                Dans ce cas, sélectionnez le mode{" "}
                <Tag color={"blue-inverse"} style={{ fontSize: 10 }}>
                  COMPTE-RENDU
                </Tag>
                . Cela rédigera un compte-rendu structuré selon le modèle
                préalablement choisi.
                <br />
                <br />
              </Typography>

              <Flex align="start" gap={"small"}>
                <InfoCircleFilled
                  style={{ color: "grey", fontSize: 12, marginTop: 2 }}
                />
                <Typography style={{ fontSize: 10 }}>
                  En mode compte-rendu, il faudra obligatoirement sélectionner
                  un modèle.
                </Typography>
              </Flex>
            </div>
          </Flex>
          <Divider type="vertical" style={{ height: 200 }}></Divider>
          <Flex vertical gap={"large"} align="center" style={{ flex: 1 }}>
            <Image
              width={150}
              src={conversationPNG}
              alt="choice"
              preview={false}
            />

            <Typography>
              <b>Vous êtes avec votre patient ?</b>
              <br />
              Dans ce cas, sélectionnez le mode{" "}
              <Tag color={"purple-inverse"} style={{ fontSize: 10 }}>
                CONVERSATION
              </Tag>
              . Cela rédigera une observation médicale.
              <br />
              <br />
            </Typography>
            <Flex align="start" gap={"small"} style={{}}>
              <InfoCircleFilled
                style={{ color: "grey", fontSize: 12, marginTop: 2 }}
              />
              <Typography style={{ fontSize: 10 }}>
                Aucun modèle nécessaire.
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref2.current,
      placement: "bottom",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"}>
          <Flex vertical gap={"small"}>
            <Typography>
              <b>Gérer vos modèles </b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              En mode{" "}
              <Tag color={"blue-inverse"} style={{ fontSize: 10 }}>
                COMPTE-RENDU
              </Tag>
              vous aurez besoin de modèles. Vous pouvez créer des modèles de
              compte-rendu ici afin de les utilisez par la suite.
            </Typography>
          </Flex>
          <FileTextFilled style={{ fontSize: 50, color: "#1677ff" }} />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref3.current,
      placement: "right",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"}>
          <Typography>
            <b>Lancer la transcription</b>
            <br />
            Avant de commencer à parler, cliquer sur ce bouton, la transcription
            commencera !
          </Typography>
          <Image
            width={50}
            src={transcriptionSVG}
            alt="choice"
            preview={false}
          />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref4.current,
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"}>
          <Typography>
            <b>Relire ou modifier la transcription</b>
            <br />
            C’est ici que Thiana retranscrit mot pour mot, ce qu’elle entend.
          </Typography>
          <EyeOutlined style={{ fontSize: 50, color: "#6240dc" }} />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref5.current,
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"} align="center">
          <Typography>
            <b>Renseigner le titre</b>
            <br />
            Nommez votre document afin de le retrouver rapidement dans votre
            liste.
          </Typography>
          <Image width={60} src={titleSVG} alt="title" preview={false} />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref6.current,
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"large"} align="center">
          <Flex vertical>
            <Typography>
              <b>Générer le document</b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              Cliquez ici pour lancer la génération du document et regardez
              Thiana rédiger une observation médicale ou un compte-rendu
              structuré et prêt à l’emploi.
            </Typography>
          </Flex>

          <Image width={100} src={generationSVG} alt="title" preview={false} />
        </Flex>
      ),
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      target: () => ref7.current,
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"large"} align="center">
          <Typography>
            <b>Relire ou modifier le document</b>
            <br />
            Attention, veillez à toujours bien relire le document généré.
          </Typography>
          <EyeOutlined style={{ fontSize: 50, color: "#6240dc" }} />
        </Flex>
      ),
      target: () => ref8.current,
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      placement: "left",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"large"} align="center">
          <Flex vertical gap={"small"} align="center" style={{ flex: 1 }}>
            <FileOutlined style={{ fontSize: 50, color: "#6240dc" }} />
            <Typography>
              <b>Retrouver vos documents</b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              C’est ici que vous retrouverez tous vos documents enregistrés
              automatiquement.
            </Typography>
          </Flex>
          <Divider type="vertical" style={{ height: 400 }}></Divider>
          <Flex vertical style={{ flex: 2 }} align="center" gap={"small"}>
            <Image width={100} src={hdsSVG} alt="title" preview={false} />
            <Typography>
              <b>100 % français</b>
            </Typography>
            <Typography style={{ textAlign: "justify" }}>
              Les données de vos patients sont sensibles. Tous nos modèles sont
              hébergés sur des serveurs privés, en France, certifiés HDS. Nous
              n'envoyons aucune donnée à aucune entreprise tierce, et encore
              moins à des GAFAM. Choisir Thiana, c'est militer pour un cloud
              français et souverain. Vous pouvez utiliser nos applications en
              toute confiance pour la sécurité des données de vos patients.
            </Typography>
          </Flex>
        </Flex>
      ),

      target: () => ref9.current,
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
      placement: "right",
    },
    {
      title: "",
      description: (
        <Flex style={{ margin: 20 }} gap={"small"} align="center">
          <Typography>
            <b>Bonus</b>
            <br />
            Avec le système d’étoiles, participez à l’amélioration continue de
            Thiana en évaluant la qualité des générations de documents.
          </Typography>
          <StarOutlined style={{ fontSize: 50, color: "#6240dc" }} />
        </Flex>
      ),

      target: () => ref10.current,
      nextButtonProps: { children: <div>Suivant</div> },
      prevButtonProps: { children: <div>Précédent</div> },
    },
    {
      title: "",
      description: (
        <Flex vertical style={{ margin: 20 }} gap={"small"} align="center">
          <Typography>
            <b>Merci d’utiliser notre solution, bonne utilisation !</b>
            <br />
          </Typography>
          <span style={{ textAlign: "right" }}>
            L’équipe Thiana <SmileOutlined />
          </span>
        </Flex>
      ),
      nextButtonProps: { children: <div>Terminer</div> },
      prevButtonProps: { children: <div>Précédent</div> },
    },
  ];

  return { stepsRef, setStepsRef, steps };
}
