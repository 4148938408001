import { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Modal,
  Progress,
  Rate,
  Select,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  WarningFilled,
} from "@ant-design/icons";
import useLLM from "../hooks/useLLM";
import Context from "../context/Context";
import { copyToClipboard } from "../functions/copyToClipboard";
import useReports from "../hooks/useReports";
import { useParams } from "react-router-dom";
import { Template } from "../types/Templates";
import useTemplates from "../hooks/useTemplates";
import { DEFAULT_TEMPLATE_UUID, EMPTY_UUID } from "../utils/constants";
import { postFeedback } from "../api/api";
import {
  ReportFeedback,
  ReportFeedbackOutput,
} from "@thiana/api-thiana-client";
import { getACL } from "../utils/security";

interface Props {
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
}
type Option = {
  value: string;
  label: string;
  content: string;
};

interface Props2 {
  templates: Template[];
  onChangeSelect: (option: Option) => Promise<void>;
  options: Option[];
  setCurrentOption: React.Dispatch<React.SetStateAction<Option | undefined>>;
  isSelectFocus: boolean;
  setIsSelectFocus: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GenerationView(props: Props) {
  let { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [currentOption, setCurrentOption] = useState<Option>();
  const [isGenerationFocus, setIsGenerationFocus] = useState(false);
  const [isTitleFocus, setIsTitleFocus] = useState(false);
  const [isSelectFocus, setIsSelectFocus] = useState(false);
  const [currentRate, setCurrentRate] = useState<number>(0);
  const [newRate, setNewRate] = useState<number>(0);
  const [newComment, setNewComment] = useState<string>("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { currentReport, updateCurrentReport, theme, reports, updateReports } =
    useContext(Context);

  useEffect(() => {
    if (currentReport && currentReport.feedbacks?.length > 0) {
      let latestFeedback: ReportFeedback =
        currentReport?.feedbacks[currentReport.feedbacks?.length - 1];
      setCurrentRate(latestFeedback.rate);
    } else setCurrentRate(0);
  }, [currentReport]);

  // useReports hook
  const { autoSaveReport, autoCreateReport, updateReport } = useReports({});
  // useTemplates hook
  const { getAllTemplates } = useTemplates({
    setTemplates: props.setTemplates,
  });

  // Récupérer tous les templates pour mettre à jour les options du <Select/>
  useEffect(() => {
    (async () => getAllTemplates(1000, 1))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tmp: Option[] = props.templates.map(
      (template: Template, index: number) => {
        return {
          value: template.id,
          label: template.title,
          content: template.content,
          disabled:
            // Si l'utilisateur est en offre Intégral, on  active tous les templates
            getACL() === 10
              ? false
              : // Sinon, on ne lui laisse que le template par défaut
              template.id === DEFAULT_TEMPLATE_UUID
              ? false
              : // On désactive les autres templates
                true,
        };
      }
    );
    // On trouve le template par défaut et on le met au début de la liste
    let index = tmp.findIndex(
      (template) => template.value === DEFAULT_TEMPLATE_UUID
    );
    if (index !== -1) {
      let el = tmp.splice(index, 1)[0];
      tmp.unshift(el);
    }

    setOptions(tmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.templates]);

  // Context
  const {
    isASRProcessing,
    isLLMProcessing,
    updateCurrentGeneration,
    updateIsSaved,
    mode,
    isSaved,
    stepsRef,
  } = useContext(Context);

  // useLLM hook
  const { fixedLLMResponseState, animatedLLMResponseState } = useLLM();

  const onChangeTitle = (newValue: string) => {
    if (currentReport && mode) {
      updateIsSaved({ isSaved: false, updatedField: "title" });
      updateCurrentReport({ ...currentReport, title: newValue });
      if (id) {
        autoSaveReport({
          ...currentReport,
          title: newValue,
        });
      } else {
        autoCreateReport({
          transcription: "",
          generation: "",
          title: newValue,
          report_type: mode,
        });
        updateIsSaved({ isSaved: true });
      }
    }
  };

  const onChangeSelect = async (option: Option) => {
    if (currentReport && mode) {
      updateIsSaved({ isSaved: false, updatedField: "template" });
      updateCurrentReport({
        ...currentReport,
        generation: option.content,
        report_template_id: option.value,
      });
      if (id) {
        let status = await updateReport({
          ...currentReport,
          generation: option.content,
          report_template_id: option.value !== "" ? option.value : EMPTY_UUID,
        });
        if (status === 200) updateIsSaved({ isSaved: true });
      } else {
        autoCreateReport({
          transcription: "",
          generation: option.content,
          title: "",
          report_type: mode,
          report_template_id: option.value !== "" ? option.value : EMPTY_UUID,
        });
        updateIsSaved({ isSaved: true });
      }
    }
  };

  const onChangeGeneration = (newValue: string) => {
    if (currentReport && mode) {
      updateIsSaved({ isSaved: false, updatedField: "generation" });
      updateCurrentReport({ ...currentReport, generation: newValue });
      if (id) {
        autoSaveReport({
          ...currentReport,
          generation: newValue,
        });
      } else
        autoCreateReport({
          transcription: "",
          generation: newValue,
          title: "",
          report_type: mode,
        });
    }
  };

  const typeWriter = (text: string, i = 0) => {
    if (i < text.length) {
      updateCurrentGeneration(fixedLLMResponseState + text.slice(0, i + 1));
      setTimeout(() => {
        typeWriter(text, i + 1);
      }, 32);
    }
  };

  useEffect(() => {
    typeWriter(animatedLLMResponseState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedLLMResponseState]);

  const createFeedback = async () => {
    if (currentReport) {
      let feedbacksResponse: ReportFeedbackOutput;
      let response = await postFeedback(currentReport.id, {
        rate: newRate,
        comment: newComment,
      });
      console.log(" Response : " + response);
      if (response.status === 201) {
        feedbacksResponse = await response.json();
        // Si on reçoit bien le nouveau feedback crée, on met à jour le report actuel pour lui ajouter le feedback créé
        let tmpFeedbacks = [...currentReport.feedbacks];
        if (feedbacksResponse.data) {
          tmpFeedbacks.push(feedbacksResponse.data[0]);
          updateCurrentReport({
            ...currentReport,
            feedbacks: tmpFeedbacks,
          });
          console.log("tmpfeedback :" + feedbacksResponse);
          console.log(
            "currentRate :" + currentRate + "currentReport :" + postFeedback
          );
        }

        // On met à jour la liste de reports pour mettre à jour les feedbacks modifiés du report actuel
        let tmpReports = [...reports];
        let index = tmpReports.findIndex(
          (report) => report.id === currentReport.id
        );
        if (index !== -1) {
          tmpReports[index] = {
            ...currentReport,
            feedbacks: tmpFeedbacks,
          };
        }
        updateReports(tmpReports);

        notification.success({
          message: "Avis envoyé ! Merci !",
          description: "Les avis nous permettent d'améliorer notre Thiana.",
          placement: "topRight",
        });
      }
      setNewComment("");
      setIsFeedbackModalOpen(false);
    }
  };
  return (
    <Flex vertical gap={12} style={{ flex: 1 }}>
      <div ref={stepsRef[6]}>
        <div style={{ marginBottom: 2 }}>
          {currentReport?.title === "" ? (
            <Tag
              bordered={false}
              style={{ fontSize: 10, marginBottom: 4 }}
              color={currentReport.title === "" ? "default" : "green"}
            >
              TITRE
            </Tag>
          ) : !isSaved.isSaved && isSaved.updatedField === "title" ? (
            <Tag
              bordered={false}
              color="orange"
              style={{ fontSize: 10, marginBottom: 4 }}
            >
              MODIFICATION EN COURS <LoadingOutlined />
            </Tag>
          ) : (
            <Tag
              bordered={false}
              color="green"
              style={{ fontSize: 10, marginBottom: 4 }}
            >
              TITRE <CheckOutlined style={{ fontSize: 8 }} />
            </Tag>
          )}
        </div>

        <Input
          value={currentReport?.title}
          onChange={(e) => onChangeTitle(e.target.value)}
          onFocus={() => (isTitleFocus ? setIsTitleFocus(true) : null)}
          onBlur={() => setIsTitleFocus(false)}
          onMouseEnter={() => setIsTitleFocus(true)}
          onMouseLeave={() => setIsTitleFocus(false)}
          data-cy="action-title"
        />
      </div>
      {mode === "statement" ? (
        <SelectTemplate
          templates={props.templates}
          options={options}
          setCurrentOption={setCurrentOption}
          isSelectFocus={isSelectFocus}
          setIsSelectFocus={setIsSelectFocus}
          setIsConfirmModalOpen={setIsConfirmModalOpen}
          onChangeSelect={onChangeSelect}
        ></SelectTemplate>
      ) : null}

      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        ref={stepsRef[8]}
      >
        <Flex
          align="center"
          wrap="nowrap"
          onMouseEnter={() => setIsGenerationFocus(true)}
          onMouseLeave={() => setIsGenerationFocus(false)}
          style={{
            color: isGenerationFocus ? "#6240dc" : "#bbbbbb",
            marginBottom: 4,
          }}
        >
          <div style={{ flex: 1 }}>
            {currentReport?.generation === "" ? (
              <Tag bordered={false} style={{ fontSize: 10 }}>
                GÉNÉRATION
              </Tag>
            ) : (!isSaved.isSaved && isSaved.updatedField === "generation") ||
              isLLMProcessing ? (
              <Flex style={{ fontSize: 10 }} gap={"small"} wrap="nowrap">
                <Progress
                  showInfo={false}
                  percent={50}
                  strokeColor={
                    mode === "conversation"
                      ? theme.colorConversation
                      : theme.colorStatement
                  }
                />
              </Flex>
            ) : (
              // <Tag bordered={false} color="orange" style={{ fontSize: 10 }}>
              //   MODIFICATION EN COURS <LoadingOutlined />
              // </Tag>
              <Tag bordered={false} color="green" style={{ fontSize: 10 }}>
                GÉNÉRATION <CheckOutlined style={{ fontSize: 8 }} />
              </Tag>
            )}
          </div>
          <div>
            <Tooltip title="Effacer la génération" mouseEnterDelay={0.8}>
              <Button
                type="text"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => setIsModalOpen(true)}
              />
            </Tooltip>

            <Tooltip title="Copier la génération" mouseEnterDelay={0.8}>
              <Button
                size="small"
                type={"text"}
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(currentReport?.generation)}
              />
            </Tooltip>
          </div>
        </Flex>
        <div
          style={{
            border: isGenerationFocus
              ? mode === "statement"
                ? "1px solid #2793f2"
                : "1px solid #6240dc"
              : theme.label === "light"
              ? "1px solid #d9d9d9"
              : "1px solid #424242",
            borderRadius: 5,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            background:
              theme.label === "dark" ? theme.sideBackgroundColor : "inherit",
          }}
        >
          <Input.TextArea
            onFocus={() =>
              isGenerationFocus ? setIsGenerationFocus(true) : null
            }
            onBlur={() => setIsGenerationFocus(false)}
            style={{
              flex: 1,
              border: "none",
              boxShadow: "none",
              textAlign: "justify",
            }}
            disabled={isASRProcessing}
            value={currentReport?.generation}
            onChange={(e) => onChangeGeneration(e.target.value)}
            onMouseEnter={() => setIsGenerationFocus(true)}
            onMouseLeave={() => setIsGenerationFocus(false)}
            data-cy="action-input-generation"
          ></Input.TextArea>

          <div
            style={{ textAlign: "center", marginTop: 6, marginBottom: 6 }}
            ref={stepsRef[10]}
          >
            <Tooltip title="Noter cette génération">
              <Rate
                allowClear={false}
                defaultValue={0}
                value={currentRate}
                onChange={(value: number) => {
                  setNewRate(value);
                  setIsFeedbackModalOpen(true);
                }}
                data-cy="action-rate"
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <Modal
        title={
          <>
            <WarningFilled style={{ color: "red", marginRight: 4 }} />
            Attention
          </>
        }
        open={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        cancelText={"Annuler"}
        okText={"Confirmer"}
        onOk={() => {
          if (currentOption) {
            onChangeSelect(currentOption);
            setIsConfirmModalOpen(false);
          }
        }}
      >
        <Typography>
          En changeant de modèle, vous allez effacer le contenu généré de votre
          document. La transcription restera inchangée. Êtes-vous sûr de vouloir
          continuer ?
        </Typography>
      </Modal>

      <Modal
        title={"Noter la génération"}
        open={isFeedbackModalOpen}
        onCancel={() => {
          setNewComment("");
          setIsFeedbackModalOpen(false);
        }}
        cancelText={"Annuler"}
        okText={<div data-cy="action-send-feedback">Envoyer mon avis</div>}
        onOk={createFeedback}
      >
        <Flex vertical gap={"small"}>
          <Flex gap={"small"} align="center">
            <Typography>
              Note<span style={{ color: "red" }}>*</span>
            </Typography>
            <Rate
              value={newRate}
              allowClear={false}
              defaultValue={0}
              onChange={(value: number) => setNewRate(value)}
            />
          </Flex>
          <Flex vertical gap={"small"}>
            <Typography>Commentaire</Typography>
            <Input.TextArea
              rows={5}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              data-cy="action-feedback"
            ></Input.TextArea>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        title={
          <>
            <ExclamationCircleFilled
              style={{
                height: 32,
                width: 32,
                fontSize: 22,
                color: "#ff4d4f",
              }}
            />
            Êtes-vous sûr de vouloir effacer l'observation médicale ?
          </>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="back"
            type="default"
            onClick={() => setIsModalOpen(false)}
          >
            Annuler
          </Button>,
          <Button
            onClick={() => {
              if (currentReport) {
                updateCurrentReport({
                  ...currentReport,
                  generation: "",
                });
                updateReport({
                  ...currentReport,
                  generation: "",
                });
                setIsModalOpen(false);
              }
            }}
            type="primary"
            danger
            key="submit"
          >
            Supprimer
          </Button>,
        ]}
      >
        <p>
          Une fois supprimées, les données seront définitivement perdues.
          Etes-vous sur de vouloir continuer ?
        </p>
        <p>Si non, cliquez sur le bouton annuler</p>
      </Modal>
    </Flex>
  );
}

function SelectTemplate(props: Props2) {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  const { currentReport } = useContext(Context);
  return (
    <div className={isVisible ? "slide-in active" : "slide-in"}>
      <Flex gap={"small"} align="center" style={{ marginBottom: 4 }}>
        {(currentReport?.transcription !== "" &&
          currentReport?.report_template_id === EMPTY_UUID) ||
        currentReport?.report_template_id === "" ||
        currentReport?.report_template_id === EMPTY_UUID ? (
          <Tag
            bordered={false}
            style={{
              fontSize: 10,
            }}
          >
            MODÈLE
          </Tag>
        ) : (
          <Tag bordered={false} color="green" style={{ fontSize: 10 }}>
            MODÈLE SÉLECTIONNÉ <CheckOutlined style={{ fontSize: 8 }} />
          </Tag>
        )}
      </Flex>
      <Select
        value={
          props.templates.find(
            (template: Template) =>
              template.id === currentReport?.report_template_id
          ) && currentReport?.report_template_id !== EMPTY_UUID
            ? currentReport?.report_template_id
            : null
        }
        onChange={(_, option) => {
          if (currentReport?.generation === "")
            props.onChangeSelect(option as Option);
          else {
            props.setIsConfirmModalOpen(true);
            props.setCurrentOption(option as Option);
          }
        }}
        placeholder="Sélectionner un modèle..."
        style={{
          width: "100%",
        }}
        options={props.options}
        onFocus={() =>
          props.isSelectFocus ? props.setIsSelectFocus(true) : null
        }
        onBlur={() => props.setIsSelectFocus(false)}
        onMouseEnter={() => props.setIsSelectFocus(true)}
        onMouseLeave={() => props.setIsSelectFocus(false)}
        data-cy="action-select-template"
      />
    </div>
  );
}
