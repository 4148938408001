import { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postAskNewTokenByScope } from "../../../api/api";
import Context from "../../../context/Context";
import { apiAuth } from "../../../api-configuration/Configuration";
import { MeOutput } from "@thiana/api-thiana-client";

export default function AuthController() {
  let navigate = useNavigate();
  let { accessTokenFromURL } = useParams();
  const { updateUser, updateProfessional, updateSubscription } =
    useContext(Context);

  useEffect(() => {
    if (accessTokenFromURL) tryToConnectWithTokensFromURL();
    else tryToConnectWithTokensFromStorage();
  });

  const tryToConnectWithTokensFromURL = async () => {
    if (accessTokenFromURL) {
      let response = await postAskNewTokenByScope(
        { scope: 1 },
        accessTokenFromURL
      );
      if (response.status === 200) {
        navigate("/documents");
      }
    }
  };

  const tryToConnectWithTokensFromStorage = async () => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    if (accessToken && refreshToken) {
      let response = await apiAuth.authUserMeRaw();
      if (response.raw.status === 200) {
        navigate("/documents");
        let me: MeOutput = await response.raw.json();
        updateUser(me.data[0].user);
        updateProfessional(me.data[0].professional);
        updateSubscription(me.data[0].subscription);
      }
    } else navigate("/logout");
  };

  return <></>;
}
