import { RiseOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import Context from "../../../context/Context";
import { Flex, Tag, Typography } from "antd";
import { getFormattedACL } from "../../../utils/security";
const stripeURL = process.env.REACT_APP_URL_STRIPE;

export default function Subscribe() {
  const { subscription, mode, theme } = useContext(Context);
  const [hasOpenStripePortal, setHasOpenStripePortal] =
    useState<boolean>(false);

  // Redirection vers le portail Stripe
  const redirectToStripe = () => {
    setHasOpenStripePortal(true);
    if (stripeURL) window.open(stripeURL, "_blank");
    else console.error("L'URL Stripe n'est pas définie.");
  };

  const reloadApp = () => {
    window.location.reload();
  };

  const getFormattedSubscription = () => {
    switch (subscription?.status) {
      case "canceled":
        return <span style={{ fontSize: 10 }}>ANNULÉ</span>;
      case "trialing":
        if (subscription?.trial_until)
          return (
            <span style={{ fontSize: 10 }}>
              JUSQU'AU {subscription?.trial_until.slice(0, 10)}
            </span>
          );
        return <span style={{ fontSize: 10 }}>ESSAI GRATUIT</span>;
      case "active":
        return <span style={{ fontSize: 10 }}>{getFormattedACL()}</span>;
      default:
        break;
    }
  };
  return (
    <div
      onClick={hasOpenStripePortal ? reloadApp : redirectToStripe}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 8,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          width: 39,
          height: 39,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RiseOutlined style={{ fontSize: 20, color: theme.iconColor }} />
      </div>

      <Flex gap={"small"} align="center">
        <Typography>
          {hasOpenStripePortal ? "Rafraichir" : "Abonnement"}
        </Typography>

        <Tag bordered={false} color={mode === "statement" ? "blue" : "purple"}>
          {getFormattedSubscription()}
        </Tag>
      </Flex>
    </div>
  );
}
