import { useContext, useEffect } from "react";
import Context from "../../../context/Context";

export default function Logout() {
  const { updateUser, updateProfessional, updateSubscription } =
    useContext(Context);
  useEffect(() => {
    localStorage.removeItem("accessJWT");
    localStorage.removeItem("refreshJWT");
    updateUser(undefined);
    updateSubscription(undefined);
    updateProfessional(undefined);

    if (process.env.REACT_APP_URL_THIANA_AUTH_LOGOUT)
      window.location.href = process.env.REACT_APP_URL_THIANA_AUTH_LOGOUT;
  });
  return <></>;
}
